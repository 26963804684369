<template>
    <div v-if="initialize" class="landing">
        <div class="position-relative">
            <section class="section">
                <div class="container d-flex">
                    <div class="col px-0">
                        <div class="row row-grid" style="color:#5271ff !important">
                            <div class="col-md-6 order-md-2">
                                <img v-lazy="'../img/svg/landing/intro.svg'" />
                            </div>
                            <div class="col-md-6 order-md-1">
                                <h1 class="display-3">
                                    {{$t('landing.presentation.requestor.intro.title.p1')}}
                                    <span>{{$t('landing.presentation.requestor.intro.title.p2')}}</span>
                                </h1>
                                <p class="lead">
                                    {{$t('landing.presentation.requestor.intro.p1')}}
                                </p>
                            </div>
                        </div>
                        <div class="row" :class="{'mt-4': !desktop, 'mt-5': desktop}">
                            <div class="col justify-content-md-center">
                                <div class="row justify-content-md-center text-center">
                                    <span class="lead col-md-auto" style="font-weight:400;">{{$t('landing.call')}}</span>
                                </div>
                                <div class="row justify-content-md-center">
                                    <div class="col-lg-8 mt-2">
                                        <div class="form-group input-group input-group-alternative shadow">
                                            <div class="input-group-prepend">
                                                <span :class="[{'input-group-text': true}]">
                                                    <i class="ni ni-pin-3 ni-lg" style="color:#5271ff !important"></i>
                                                </span>
                                            </div>
                                            <input ref="local" style="height: 55px;" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>

        <section class="section bg-secondary">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <img v-lazy="'../img/svg/landing/request.svg'" />
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <div class="row row-grid align-items-center px-3">
                                <icon name="ni ni ni-square-pin" class="col-md-auto" size="md" type="white" color="primary" shadow rounded></icon>
                                <h3 class="col">{{$t('landing.presentation.requestor.request.title')}}</h3>
                            </div>
                            <p class="lead">
                                {{$t('landing.presentation.requestor.request.p1')}}
                            </p>
                            <p>
                                {{$t('landing.presentation.requestor.request.p2')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                        <img v-lazy="'../img/svg/landing/benefit.svg'" />
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <div class="row row-grid align-items-center px-3">
                                <icon name="ni ni-active-40" class="col-md-auto" size="md" type="white" color="primary" shadow rounded></icon>
                                <h3 class="col">{{$t('landing.presentation.requestor.benefit.title')}}</h3>
                            </div>
                            <p class="lead">
                                {{$t('landing.presentation.requestor.benefit.p1')}}
                            </p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="primary" class="mr-3" icon="ni ni-building ni-lg"></badge>
                                        <h6 class="mb-0">{{$t('landing.presentation.requestor.benefit.p2')}}</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="primary" class="mr-3" icon="ni ni-check-bold ni-lg"></badge>
                                        <h6 class="mb-0">{{$t('landing.presentation.requestor.benefit.p3')}}</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="primary" class="mr-3" icon="ni ni-chat-round ni-lg"></badge>
                                        <h6 class="mb-0">{{$t('landing.presentation.requestor.benefit.p4')}}</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="realtor" class="section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-3 shape-default shape-skew">
            </div>
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-1">
                        <h2 class="display-3 text-white">{{type == 'requestor' ? $t('landing.presentation.requestor.call.title') : $t('landing.presentation.realtor.call.title')}}</h2>
                        <p class="lead text-white">
                            {{type == 'requestor' ? $t('landing.presentation.requestor.call.p1') : $t('landing.presentation.realtor.call.p1')}}
                        </p>
                    </div>
                    <div class="col-md-6 order-md-2">
                        <register-card :type="type"
                                        source="landing"
                                        @type-change="type = $event"
                                        :showType="false">
                        </register-card>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <img v-lazy="'../img/svg/landing/realtor.svg'" />
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <div class="row row-grid align-items-center px-3">
                                <icon name="ni ni ni-briefcase-24" class="col-md-auto" size="md" type="white" color="primary" shadow rounded></icon>
                                <h3 class="col">{{$t('landing.presentation.realtor.benefit.title')}}</h3>
                            </div>
                            <p class="lead">
                                {{$t('landing.presentation.realtor.benefit.p1')}}
                            </p>
                            <p>
                                {{$t('landing.presentation.realtor.benefit.p2')}}
                            </p>
                            <p>
                                {{$t('landing.presentation.realtor.benefit.p3')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <!--<base-button v-if="!isRealtor" type="primary" id="requestButton" @click="$router.push('request')">{{$t('landing.button')}}</base-button>-->
    </div>
</template>
<script>
    import RegisterCard from '../components/RegisterCard'
    import gmapsInit from '../utils/gmaps';

    export default {
        name: 'Landing',
        components: {
            RegisterCard,
        },
        data() {
            return {
                initialize: false,
                type: 'realtor',
                desktop: screen.width > 750,
                isRealtor: false,
            }
        },

        methods: {
            enterEarlySession(data) {
                var early_session = this.$store.state.early_session;
                early_session.request.available = true;
                early_session.request.data = data;
            },

            async initGoogleMaps() {
                //var updateInput = this.updateInput;
                var early_data;
                var enterEarlySession = this.enterEarlySession;
                var router = this.$router;
                const google = await gmapsInit();
                const input = this.$refs.local;
                const autocomplete = new google.maps.places.Autocomplete(
                    input, { types: ['(cities)'], componentRestrictions: { country: 'br' } });
                autocomplete.setFields(['address_component', 'geometry']);
                autocomplete.addListener('place_changed', function () {
                    const place = autocomplete.getPlace();
                    if (!place.geometry) {
                        input.value = '';
                    } else {
                        early_data = {
                            place: place.geometry.location.toString(),
                            location: input.value,
                            address_components: place.address_components,
                        }
                        enterEarlySession(early_data);
                        router.push('/request');
                    }
                });
            }
        },

        mounted() {

            if (this.$store.state.logged_in) {
                this.$router.push('dashboard');
            } else {
                this.initialize = true;
                this.initGoogleMaps();
            }

            if (location.hash == '#realtor' || location.hash == '#corretor') {
                this.isRealtor = true;
                this.$nextTick(() => {
                    this.$scrollTo('#realtor')
                });
            }
        }
    };
</script>
<style scoped>
    .landing {
        text-align: left;
    }

    #requestButton {
        position: fixed;
        margin: 0 auto;
        bottom: 25px;
        z-index: 99;
        right: 25px;
    }
</style>